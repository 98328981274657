import React, { Component } from 'react'

import CardService from './cardService';
import api from '../api'

export default class service extends Component {

    constructor(props) {
        super(props);
        this.state = {
            services:[]
        }
    }

    componentDidMount(){
        api.get("/service/")
        .then(res=> {
            console.log(res.data)
            if(res.status==200){
                this.setState({services:res.data.services})
            } 
        }).catch(err=> {
            console.log(err)
            if(err.response.data.error="failed to authenticate token"){
                this.props.setToken("")
            }
        })
    }


    render() {
        const {services} =this.state;
        return (
            <div className="pt-4 px-5">
                <h1 className='services'>Mods/Plugin</h1>
                {
                    services.map(serv => {
                        return <CardService service={serv}/>  
                    })
                }
                
            </div>
        )
    }
}
