import React, { Component } from 'react'
import api from '../api'

export default class cardService extends Component {

    constructor(props) {
        super(props)
        this.state = {
            authKeys: [],
            newUsername: ""
        }
    }
    newUsernameHandler = (e) => {
        this.setState({ newUsername: e.target.value })
    }

    componentDidMount() {
        this.updateAuthKeys()
    }

    renewToken(authKeyId) {
        console.log(authKeyId)
        api.put("/authkey/", { authKeyId })
            .then(res => {
                if (res.status == 200) {
                    this.updateAuthKeys();
                }
            })
    }

    copy(key) {
        navigator.clipboard.writeText(key);
    }

    revoke(authKeyId) {
        console.log(authKeyId)
        api.delete("/authkey/", { data: { authKeyId } })
            .then(res => {
                if (res.status == 200) {
                    this.updateAuthKeys();
                }
            })
    }

    new = () => {
        const { service } = this.props
        const { newUsername } = this.state
        console.log(newUsername)
        api.post("/authkey/", { username: newUsername, service: service.name })
            .then(res => {
                if (res.status == 200) {
                    this.updateAuthKeys();
                }
            })
    }

    updateAuthKeys() {
        const { name } = this.props.service
        api.get("/authkey/" + name)
            .then(res => {
                if (res.status == 200) {
                    this.setState({ authKeys: res.data.authKeys })
                }
            })
    }
    calcDifferentIpNumber(ips) {

        return [...new Set(ips.map(ip => ip.split("#")[0]))].length
    }

    render() {
        const { service } = this.props
        const { authKeys, newUsername } = this.state
        return (
            <div class="services card text-white bg-secondary mb-3" >
                <div class="card-header">{service.name}</div>


                <div class="card-body m-0 p-2 pl-3">
                    {
                        authKeys.map((authKey, i) => {
                            return <div key={i} className='mb-2 d-flex flex-row align-items-center' >
                                <div className='d-flex flex-row'>
                                    <div className="mx-1" style={{ width: "120px" }}>{authKey.username}</div>
                                    <div className='mx-3' style={{ width: "300px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{authKey.key.includes("KEY") ? authKey.key : "..." + authKey.key.substring(50)}</div>
                                    <div>{this.calcDifferentIpNumber(authKey.ips)} IPs </div>
                                </div>
                                <div style={{ width: "200px" }} className='mx-4 d-flex flex-row justify-content-between' >
                                    <button onClick={() => this.copy(authKey.key)} style={{ color: "white" }} className='copy-key btn btn-info btn-sm '>Copy</button>
                                    <button onClick={() => this.renewToken(authKey._id)} style={{ color: "white" }} className='btn btn-success btn-sm '>Renew</button>
                                    <button onClick={() => this.revoke(authKey._id)} style={{ color: "white" }} className='btn btn-danger btn-sm '>Revoke</button>
                                </div>
                            </div>

                        })
                    }
                    <div className='mb-2 d-flex flex-row align-items-center' >
                        <div className='d-flex flex-row'>
                            <input value={newUsername} onChange={this.newUsernameHandler} style={{ width: "120px" }} class="form-control form-control-sm" type="text" placeholder="username" />
                        </div>
                        <div style={{ width: "200px" }} className='mx-4 d-flex flex-row justify-content-between' >
                            <button onClick={this.new} style={{ color: "white", width: "100px" }} className='copy-key btn btn-dark btn-sm '>New</button>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
