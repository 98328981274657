import React, { Component } from 'react'

import api from '../api'

export default class login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            signUp: false,
            username: "",
            password: "",
            passwordHandler: "",

        }
    }

    usernameHandler = (e) => {
        this.setState({ username: e.target.value })
    }

    passwordHandler = (e) => {
        this.setState({ password: e.target.value })
    }

    passwordConfirmHandler = (e) => {
        this.setState({ passwordConfirm: e.target.value })
    }

    signUpButton = () => {
        this.setState({ signUp: true })
    }

    login = () => {
        const { password, username } = this.state;
        api.post("/auth/login",
            {
                username,
                password
            })
            .then(res => {
                if (res.status == 200) {
                    this.props.setToken(res.data.token)
                }
            }).catch(err => { })

    }

    signUp = () => {
        const { password, username, passwordConfirm } = this.state;
        api.post("/auth/register",
            {
                username,
                password,
                password_confirmation:passwordConfirm
            })
            .then(res => {
                if (res.status == 200) {
                    this.props.setToken(res.data.token)
                }
            }).catch(err => { })

    }

    render() {
        const { signUp } = this.state;
        const { password, passwordConfirm, username } = this.state;
        return (

            <div className="card-body p-5 text-center">

                <div id="infoContainer" className="mb-md-5 mt-md-4 pb-5">

                    <h2 className="fw-bold mb-4 text-uppercase ">Login</h2>

                    <div className="form-outline form-white mb-4">
                        <input value={username} onChange={this.usernameHandler} type="text" id="typeUsername" className="form-control form-control-lg" />
                        <label className="form-label" htmlFor="typeUsername">Username</label>
                    </div>

                    <div className="form-outline form-white mb-4">
                        <input value={password} onChange={this.passwordHandler} type="password" id="typePassword" className="form-control form-control-lg" />
                        <label className="form-label" htmlFor="typePassword">Password</label>
                    </div>

                    {signUp &&
                        <div className="form-outline form-white mb-4">
                            <input value={passwordConfirm} onChange={this.passwordConfirmHandler} type="password" id="typePasswordConfirm" className="form-control form-control-lg" />
                            <label className="form-label" htmlFor="typePasswordConfirm">Confirm Password</label>
                        </div>
                    }

                    {
                        !signUp ? <button onClick={this.login} className="btn btn-outline-light btn-lg px-5" type="submit">Login</button>
                            : <button onClick={this.signUp} className="btn btn-outline-light btn-lg px-5" type="submit">Register</button>
                    }

                </div>

                <div>
                    <p className="mb-0">Don't have an account? <a onClick={this.signUpButton} className="text-white-50 fw-bold hover-cursor">Sign
                        Up</a>
                    </p>
                </div>

            </div>
        )
    }
}
