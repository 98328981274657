import axios from "axios";

const api = axios.create({
    baseURL: 'https://dev-square.tgro.fr/api/',
});


api.interceptors.request.use(function(config) {
    // Do something before request is sent
    if (localStorage.getItem('token')) {
        let authKey = localStorage.getItem('token');
        config.headers["Authorization"] = "Basic " + authKey;
    }
    return config;
});

export default api;