import React, { Component } from 'react'
import './App.css';

import Login from './components/login'
import Service from './components/service'

export default class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      token: "",

    }
  }
  componentDidMount(){
    if(localStorage.getItem('token')){
      this.setState({token:localStorage.getItem('token')})
    }
  }

  setToken = (token) => {
    localStorage.setItem('token',token)
    this.setState({ token })
  }

  render() {
    const { token } = this.state;
    return (
      <section className="App gradient-custom">
        <div className="container py-3 h-100">
          <div className="d-flex justify-content-center h-100">

            <div className={token == "" ? "main-container-login" : "main-container-admin"}>
              {
                token == "" ?
                  <div className="card bg-dark text-white" style={{ borderRadius: "1rem" }}>

                    <Login setToken={this.setToken} />
                  </div>
                  :
                  <div className="card bg-dark text-white" style={{ minHeight: "100%", borderRadius: "1rem" }}>
                    <Service setToken={this.setToken} token={token}/>
                  </div>
              }
            </div>
          </div>
        </div>
      </section>
    );
  }
}

